import { FC, useEffect, useState } from 'react'
import CN from 'classnames'
import {
  useGetPartnerIntegrationById,
  useUpdateInboundOrderCutoffTime,
} from 'framework/api/methods'

// import { useCreateCutoffTime } from 'framework/api/methods'
import { Input, SimpleSelect, ToggleSwitch } from 'components/atoms'
import { Button } from 'components/atoms/Button'
import { Drawer } from 'components/atoms/Drawer'

export interface UpdateInboundOrderCutOffTimeProps {
  className?: string | undefined
  [x: string]: any
}
export const UpdateInboundOrderCutOffTime: FC<
  UpdateInboundOrderCutOffTimeProps
> = ({
  className,
  isActive,
  onCloseClick,
  partnerList,
  notify,
  getInboundOrderCutOffTimeList,
  selectedRowByButtonAction,
}: UpdateInboundOrderCutOffTimeProps) => {
  const CutOffTimeClasses = CN(
    'UpdateInboundOrderCutOff flex flex-col py-4 px-5 relative h-full overflow-y-auto overscroll-y-auto styled-scroll gap-y-5',
    className,
    {},
  )
  const [integrationList, setIntegrationList] = useState([])
  const [criteriaList, setCriteriaList] = useState([
    { label: 'Type Of Container', value: 'TypeOfContainer' },
    { label: 'Volume', value: 'Volume' },
  ])
  const [containerTypeList, setcontainerTypeList] = useState([
    { label: '40ft Loose', value: '40ft Loose' },
    { label: '40ft Palletised', value: '40ft Palletised' },
    { label: '20ft Loose', value: '20ft Loose' },
    { label: '20ft Palletised', value: '20ft Palletised' },
  ])
  const [inboudOrderCutoffTimeDetails, setInboundOrderCutoffTimeDetails] =
    useState<any>({
      partnerCode: '',
      partnerName: '',
      integrationType: '',
      integrationId: '',
      integrationURL: '',
      criteria: null,
      containerTypes: null,
      numberOfLines: 0,
      inboundCutOffLeadTime: 0,
      isActive: true,
    })
  const [isErrorField, setIsErrorField] = useState<any>({
    partnerName: false,
    integrationType: false,
    criteria: false,
    containerTypes: false,
    numberOfLines: false,
    inboundCutOffLeadTime: false,
  })

  useEffect(() => {
    console.log('selectedRowByButtonAction', selectedRowByButtonAction)
    const {
      id,
      partnerCode,
      partnerName,
      integrationType,
      integrationId,
      integrationURL,
      criteria,
      containerType,
      numberOfLines,
      inboundCutOffLeadTime,
      isActive,
    } = selectedRowByButtonAction || {}
    setInboundOrderCutoffTimeDetails({
      ...inboudOrderCutoffTimeDetails,
      id,
      partnerCode,
      partnerName,
      integrationType: integrationURL
        ? integrationType + ` - ` + integrationURL
        : integrationType,
      integrationId,
      criteria:
        criteria === 'Volume' || criteria === 'Type Of Container'
          ? criteriaList.find(
              (x) => x.label === criteria || x.value === criteria,
            ) || null
          : criteria,
      containerTypes: containerTypeList.find(
        (x: { label: any }) => x.label === containerType,
      ),
      numberOfLines,
      inboundCutOffLeadTime,
      isActive,
    })

    console.log('inboudOrderCutoffTimeDetails', inboudOrderCutoffTimeDetails)
  }, [selectedRowByButtonAction])

  /** API Call for create cutoff time using react-query */
  const {
    mutate: updateInboundOrderCutoffTimeMutate,
    isLoading: updateInboundOrderCutoffTimeIsLoading,
  } = useUpdateInboundOrderCutoffTime()
  /** Process the create cutoff time */
  async function updateInboundOrderCutoffTime() {
    updateInboundOrderCutoffTimeMutate(
      {
        id: selectedRowByButtonAction?.id,
        partnerCode: inboudOrderCutoffTimeDetails?.partnerCode,
        partnerName: inboudOrderCutoffTimeDetails?.partnerName,
        integrationType:
          inboudOrderCutoffTimeDetails?.integrationType?.split(' - ')[0],
        integrationId: inboudOrderCutoffTimeDetails?.integrationId,
        integrationURL: inboudOrderCutoffTimeDetails?.integrationURL || null,
        criteria: inboudOrderCutoffTimeDetails?.criteria?.label,
        containerTypes: inboudOrderCutoffTimeDetails?.containerType?.label,
        numberOfLines: parseInt(inboudOrderCutoffTimeDetails?.numberOfLines),
        inboundCutOffLeadTime: parseInt(
          inboudOrderCutoffTimeDetails?.inboundCutOffLeadTime,
        ),
        isActive: inboudOrderCutoffTimeDetails?.isActive,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          notify(successData.message, 'success')
          onCloseClick()
          getInboundOrderCutOffTimeList()
        },
        onError: ({ response: { data: errData } }: any) => {
          notify(errData.message || 'Error', 'error')
        },
      },
    )
  }
  /** API Call using react-query */
  const {
    mutate: getPartnerIntegrationByIdMutate,
    isLoading: getPartnerIntegrationByIdIsLoading,
  } = useGetPartnerIntegrationById()
  /** Process to get partner Integrations */
  async function getPartnerIntegrationById(
    id: any,
    withSftpIntegration: boolean,
  ): Promise<void> {
    getPartnerIntegrationByIdMutate(
      { id, withSftpIntegration },
      {
        onSuccess: ({ data: successData }: any) => {
          const result: any = []
          successData.detailedPartnerIntegrations.forEach((item: any) => {
            if (item.isActive) {
              let label
              if (item.name === 'PushApi') {
                label = item.name
              } else if (item.name === 'AzureSftp') {
                label = `${item.name} - ${item.container}`
              } else {
                label = `${item.name} - ${item.baseUrl?.split('//')[1]}`
              }

              result.push({
                label,
                value: item?.id,
                url: item?.baseUrl?.split('//')[1],
              })
            }
          })
          setIntegrationList(result)
        },
        onError: ({ response: { data: errData } }: any) => {
          notify(errData.message || 'Error', 'error')
        },
      },
    )
  }
  const validateInputs = () => {
    const {
      partnerName,
      integrationType,
      criteria,
      containerTypes,
      numberOfLines,
      inboundCutOffLeadTime,
    } = inboudOrderCutoffTimeDetails
    console.log('inboudOrderCutoffTimeDetails', inboudOrderCutoffTimeDetails)
    setIsErrorField({
      ...isErrorField,

      partnerName: partnerName ? false : true,
      integrationType: integrationType ? false : true,
      criteria: criteria ? false : true,
      inboundCutOffLeadTime:
        inboundCutOffLeadTime != 0 && inboundCutOffLeadTime ? false : true,
      containerTypes:
        inboudOrderCutoffTimeDetails?.criteria?.value != 'TypeOfContainer' ||
        (containerTypes != null && containerTypes != '')
          ? false
          : true,
      numberOfLines:
        inboudOrderCutoffTimeDetails?.criteria?.value != 'Volume' ||
        (numberOfLines != null && numberOfLines != '' && numberOfLines != 0)
          ? false
          : true,
    })
    console.log('partnerName', partnerName)
    console.log('integrationType', integrationType)
    console.log('criteria', criteria)
    console.log('containerTypes', containerTypes)
    console.log('numberOfLines', numberOfLines)
    console.log(
      'LeadTime',
      inboundCutOffLeadTime === 0 || inboundCutOffLeadTime,
    )

    const isAllValidFields =
      partnerName &&
      integrationType &&
      criteria &&
      (criteria != 'TypeOfContaine' || containerTypes) &&
      (criteria != 'Volume' || numberOfLines) &&
      inboundCutOffLeadTime
    console.log(isAllValidFields)
    return isAllValidFields
  }
  const handleUpdateClick = () => {
    const valid = validateInputs()
    if (valid) {
      updateInboundOrderCutoffTime()
    }
  }
  return (
    <Drawer
      isActive={isActive}
      onCloseClick={onCloseClick}
      width={400}
      title='Add New Cut-off Time'>
      <div className={CutOffTimeClasses}>
        <div className='flex flex-col w-full mt-1'>
          <span className='pb-2 text-N-700'>
            Partner Name
            <span className='text-R-500 ml-[2px]'>*</span>
          </span>
          <SimpleSelect
            placeholder='Select Partner'
            disabled={true}
            value={
              inboudOrderCutoffTimeDetails?.partnerName
                ? {
                    label: inboudOrderCutoffTimeDetails?.partnerName,
                    value: inboudOrderCutoffTimeDetails?.partnerCode,
                  }
                : null
            }
            onChange={(e: any) => {
              setInboundOrderCutoffTimeDetails({
                ...inboudOrderCutoffTimeDetails,
                partnerName: e.label,
                partnerCode: e.value,
                integrationType: '',
                integrationId: '',
                integrationURL: '',
              })
              getPartnerIntegrationById(e.id, true)
            }}
            options={partnerList}
            isError={isErrorField?.partnerName}
          />
        </div>
        <div className='flex flex-col w-full'>
          <span className='pb-2 text-N-700'>
            Partner Integration <span className='text-R-500 ml-[2px]'>*</span>
          </span>
          <SimpleSelect
            disabled={true}
            placeholder='Select Partner Integration'
            value={
              inboudOrderCutoffTimeDetails?.integrationType
                ? {
                    label: inboudOrderCutoffTimeDetails?.integrationType,
                    value: inboudOrderCutoffTimeDetails?.integrationId,
                  }
                : null
            }
            onChange={(e: any) => {
              setInboundOrderCutoffTimeDetails({
                ...inboudOrderCutoffTimeDetails,
                integrationType: e.label,
                integrationId: e.value,
                integrationURL: e.url,
              })
            }}
            options={integrationList}
            isError={isErrorField?.integrationType}
          />
        </div>
        <div className='flex flex-col w-full'>
          <span className='pb-2 text-N-700'>
            Criteria <span className='text-R-500 ml-[2px]'>*</span>
          </span>
          <SimpleSelect
            placeholder='Select Criteria'
            value={inboudOrderCutoffTimeDetails?.criteria}
            onChange={(e: any) => {
              setInboundOrderCutoffTimeDetails({
                ...inboudOrderCutoffTimeDetails,
                criteria: e,
              })
            }}
            options={criteriaList}
            isError={isErrorField?.criteria}
          />
        </div>
        {inboudOrderCutoffTimeDetails?.criteria?.value ===
          'TypeOfContainer' && (
          <div className='flex flex-col w-full'>
            <span className='pb-2 text-N-700'>
              Container Types <span className='text-R-500 ml-[2px]'>*</span>
            </span>
            <SimpleSelect
              placeholder='Select Container Type'
              value={inboudOrderCutoffTimeDetails?.containerType}
              onChange={(e: any) => {
                setInboundOrderCutoffTimeDetails({
                  ...inboudOrderCutoffTimeDetails,
                  containerType: e,
                  numberOfLines: 0,
                })
              }}
              options={containerTypeList}
              isError={isErrorField?.containerTypes}
            />
          </div>
        )}
        {inboudOrderCutoffTimeDetails?.criteria?.value === 'Volume' && (
          <Input
            labelValue='Enter the Number Of Lines'
            value={inboudOrderCutoffTimeDetails.numberOfLines}
            onChange={(e: any) => {
              const re = /^(?:0|[1-9]\d*)$/
              // if value is not blank, then test the regex
              if (e.target.value === '' || re.test(e.target.value)) {
                setInboundOrderCutoffTimeDetails({
                  ...inboudOrderCutoffTimeDetails,
                  numberOfLines: e.target.value,
                  containerTypes: null,
                })
              }
            }}
            isError={isErrorField.numberOfLines}
          />
        )}

        <Input
          labelValue='Inbound Order CutOff Lead Time (Hours)'
          isRequired
          value={inboudOrderCutoffTimeDetails.inboundCutOffLeadTime}
          onChange={(e: any) => {
            const re = /^(?:0|[1-9]\d*)$/
            // if value is not blank, then test the regex
            if (e.target.value === '' || re.test(e.target.value)) {
              setInboundOrderCutoffTimeDetails({
                ...inboudOrderCutoffTimeDetails,
                inboundCutOffLeadTime: e.target.value,
              })
            }
          }}
          isError={isErrorField.inboundCutOffLeadTime}
        />
        <div className='flex flex-col w-full'>
          <span className='pb-2 text-N-700'>Status</span>
          <ToggleSwitch
            text={'Active'}
            isDisabled={false}
            setValue={inboudOrderCutoffTimeDetails?.isActive}
            onToggleClick={(e: any) => {
              setInboundOrderCutoffTimeDetails({
                ...inboudOrderCutoffTimeDetails,
                isActive: e.target.checked,
              })
            }}
          />
        </div>
      </div>
      <div className='drawer-footer flex items-center w-full h-[67px] sticky bottom-0 px-5 border-t border-N-200 shadow-sm gap-x-5'>
        <Button
          isLoading={updateInboundOrderCutoffTimeIsLoading}
          disabled={updateInboundOrderCutoffTimeIsLoading}
          isFullWidth
          onClick={() => {
            handleUpdateClick()
          }}>
          Save
        </Button>
        <Button isFullWidth appearance='dull' onClick={onCloseClick}>
          Close
        </Button>
      </div>
    </Drawer>
  )
}
UpdateInboundOrderCutOffTime.defaultProps = {
  className: undefined,
}

export default UpdateInboundOrderCutOffTime
